import NavigationBar from "./NavigationBar";
import Header from "./header";
import bgVideo from '../src/assets/background_temple.mp4';

const BgTheme = ({children}) => {
    return (
        <div className="relative h-screen w-full overflow-hidden">
            <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-full min-w-full object-cover"
            >
                <source src={bgVideo} type="video/mp4" />
            </video>

            {children}
        </div>
    )
}

export default BgTheme;
