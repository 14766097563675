import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from "react-router-dom";
import solLogo from "../src/assets/currency-sol-logo.png";
import btcLogo from "../src/assets/currency-btc-logo.png";
import ethLogo from "../src/assets/currency-eth-logo.png";
import bonkLogo from "../src/assets/currency-bonk-logo.png";
import PawLogo from "../src/assets/paypawlogo.png";
import usdcLogo from "../src/assets/currency-usdc-logo.png";
import usdtLogo from "../src/assets/currency-usdt-logo.png";
import dogeLogo from "../src/assets/currency-doge-logo.png";
import shibLogo from "../src/assets/currency-shib-logo.png";
import pepeLogo from "../src/assets/currency-pepe-logo.png";
import polygonLogo from "../src/assets/currency-polygon-logo.svg";
import { useWallet } from '@solana/wallet-adapter-react';
import { authenticateWithSolana } from './authService';

const SelectCurrency = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const currency = location.state?.currency;
  const provider = location.state?.provider;
  const amount = location.state?.amount;
  const email = location.state?.email;
  const cardType = location.state?.type;
  const { publicKey, connected } = useWallet();
  const coins = 
  [
    {
      name: "SOL",
      ticker: "SOL",
      network: "Mainnet",
      logo: solLogo
    },
    {
      name: "ETH",
      ticker: "ETH",
      network: "ERC20",
      logo: ethLogo
    },
    {
      name: "BTC",
      ticker: "BTC",
      network: "Mainnet",
      logo: btcLogo
    },
    {
      name: "BONK",
      ticker: "BONK",
      network: "SOL",
      logo: bonkLogo
    },
    {
      name: "USD Coin (SOL)",
      ticker: "USDC",
      network: "SOL",
      logo: usdcLogo
    },
    {
      name: "USD Coin (ERC20)",
      ticker: "USDC",
      network: "ERC20",
      logo: usdcLogo
    },
    {
      name: "USD Coin (BSC)",
      ticker: "usdc",
      network: "BEP20",
      logo: usdcLogo
    },
    {
      name: "Tether (SOL)",
      ticker: "USDT",
      network: "SOL",
      logo: usdtLogo
    },
    {
      name: "Tether (ERC20)",
      ticker: "USDT",
      network: "ERC20",
      logo: usdtLogo
    },
    {
      name: "Tether (BSC)",
      ticker: "usdt",
      network: "BEP20",
      logo: usdtLogo
    },
    {
      name: "Dogecoin",
      ticker: "doge",
      network: "Mainnet",
      logo: dogeLogo
    },
    {
      name: "Shiba Inu (ERC20)",
      ticker: "shib",
      network: "ERC20",
      logo: shibLogo
    },
    {
      name: "Pepe (ERC20)",
      ticker: "pepe",
      network: "ERC20",
      logo: pepeLogo
    },
    {
      name: "Polygon (Polygon)",
      ticker: "matic",
      network: "Mainnet",
      logo: polygonLogo
    }
  ];

  const goToSelectCountry = () => {
    navigate("/select-country", { state: { type: cardType } });
  };

  const handleCardSelection = async (reqData) => {
    setIsLoading(true); // Start loading

    let jwt = '';

    // authenticate if wallet is connected
    if (connected && publicKey) {
      try {
        jwt = await authenticateWithSolana(publicKey.toString());
      } catch (error) {
        console.error("Failed to authenticate with wallet:", error);
      }
    }

    try {
      let response = {};
      if (cardType === "gift") {
        response = await fetch('https://paypawcards.com/paypawbackend/order-gift-card', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt ? `Bearer ${jwt}` : undefined
          },
          body: JSON.stringify({
            ...reqData,
            publicKey: publicKey ? publicKey.toString() : undefined,
          })
        });
      } else {
        response = await fetch('https://paypawcards.com/paypawbackend/order-card', { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwt ? `Bearer ${jwt}` : undefined
          },
          body: JSON.stringify({
            ...reqData,
            publicKey: publicKey ? publicKey.toString() : undefined,
          })
        });
      }

      if (response.status == 403) {
        throw new Error('403');
      }
      
      if (!response.ok) {
        throw new Error('Failed to create order');
      }
      const responseData = await response.json();
      setIsLoading(false); // Stop loading
      // Navigate to PaymentQRCode page with responseData
      navigate("/payment-qr-code", { state: { qrCodeData: responseData.qrcode, tradeId: responseData.trade_id, address: responseData.address_provider, amount: responseData.amount_from, ticker: responseData.ticker, emailProvided: email !== "" } });
    } catch (error) {
      if (error.message == '403') {
        toast.error("Error Creating card. Must have over 100,000 $Landlordpp Tokens. Please authenticate with your wallet and order again!", {
          position: "top-left"
        });
      } else {
        toast.error("Error selecting currency - Please try again. If the problem persists please contact support!", {
          position: "top-left"
        });
      }
      console.error('Error making POST request:', error);
      setIsLoading(false); // Stop loading in case of error
      // Handle error, e.g., show an error message to the user
    }
  };

  if (isLoading) {
    return <div className="flex flex-col items-center justify-center">
      <div>
        <div className="divide-logo">
        </div>
        <div className="coming-soon-text text-xl font-extrabold p-2 ps-2 pb-9">LOADING</div>
        <div className="flex items-center justify-center">
          <img src={PawLogo} alt="paw logo" className="max-h-11 rotate"/>
        </div>
      </div>
    </div>;
  }

  return (
    <div className="w-full h-full px-4">
      <div>
        <div className="flex justify-center pb-2">
          <div className="step-box border-fuchsia-800">
            <div className="step-text flex justify-center items-center">
              5
            </div>
          </div>
        </div>
        <div>
          <div className="text-white flex justify-center items-center pb-1">
            <strong>Step 5</strong>
          </div>
          <div className="gray-text flex justify-center items-center pb-4">
            Select a currency to pay with
          </div>
          <hr className="step-divider"></hr>
          <div className="pb-2"></div>
        </div>

        <div className="flex-1 flex flex-wrap items-start justify-center sm:justify-start gap-2 sm:gap-4 h-100 overflow-y-auto w-full pb-20">
          {coins.map((coin) => (
            <div
              key={coin.name} // Assuming logo URLs are unique
              className="display-card card-select rounded-lg p-4 flex flex-col items-center justify-center "
              onClick={() => handleCardSelection({
                currency: currency,
                provider: provider,
                amount: ((cardType === 'gift') ? amount : amount.trim()),
                ticker: coin.ticker,
                network: coin.network,
                email: email,
                type: cardType,
                source: "landlordpp"
              })}
            >
              <div>
                <div className="flex items-center justify-center">
                  <img
                    src={coin.logo}
                    alt={coin.name}
                    className="h-20 max-w-20"
                  />
                </div>
                <div className="text-white pt-8">
                  <div className="flex justify-center">
                  {coin.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-start">
          <button
            onClick={goToSelectCountry}
            className="hover:bg-opacity-80 text-white font-bold back-card"
          >
            ← <small>Back</small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectCurrency;
