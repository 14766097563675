import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select'
import visaCard from "../src/assets/visa.svg";
import mastercardCard from "../src/assets/mastercard.svg";
import important from "../src/assets/i-icon.svg";
import searchImg from "../src/assets/search.svg";
import dollarImg from "../src/assets/dollars.svg";
import DOMPurify from 'dompurify';

const SelectGiftCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCountryCode = location.state?.country?.value;
  const selectedCountry = location.state?.country;
  const email = location.state.email;
  const cardType  = location.state.type;
  const [giftcards, setGiftCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [options, setOptions] = useState(0);
  const [cardSearch, setCardSearch] = useState("");
  const [cardAmount, setCardAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({name: "", description: "", terms_and_conditions: "", how_to_use: "", expiry_and_validity: "", card_image_url: "", min_amount: 0, max_amount: 10});

  useEffect(() => {
    const fetchGiftCards = async () => {
      try {
        let country = selectedCountryCode
        if (selectedCountryCode === "United States") {
          country = "USA"
        }
        if (selectedCountryCode === "United Kingdom") {
          country = "UK"
        }

        const response = await fetch(`https://paypawcards.com/paypawbackend/get-gift-cards/${country}`);
        
        if (response.status == 404) {
          throw new Error('404');
        }
        if (!response.ok) {
          throw new Error('Failed to select giftcard information');
        }
        const data = await response.json();
        
        setGiftCards(data.cards);
        setOptions(data.cards.length);
        setFilteredCards(data.cards);
        var categoryList = [];
        data.categories.forEach(function(category) {
          categoryList.push({ label: category, value: category })
        });
        setCategories(categoryList); 
      } catch (error) {
        if (error.message == '404') {
          toast.error("Error selecting giftcard info - This contry currently does not have any giftcard available for use!", {
            position: "top-left"
          });
        }
        console.error("Failed to fetch giftcard", error);
      }
    };

    if (selectedCountryCode) {
      fetchGiftCards();
    }
  }, [selectedCountryCode]);

  const goToSelectCountry = () => {
    navigate("/select-country", { state: { type: cardType } });
  };

  const searchCards = () => {
  };

  const selectCard = (card) => {
    setSelectedCard(card)
    setShowModal(true)
  };

  const HTMLDisplay = ({ htmlString }) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
  
    return (
      <div
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
    );
  };

  const handleCategoryChange = (category) => {
    let filtered = [];
    if (category === null) {
      setSelectedCategory("");
      setFilteredCards(giftcards)
      filtered = giftcards
    } else {
      setSelectedCategory(category.value);
      filtered = giftcards.filter(card => card.category === category.value)
      setFilteredCards(filtered);
    }

    setOptions(filtered.length)
  };

  const purchaseCard = () => {
    if (parseInt(cardAmount) > parseInt(selectedCard.max_amount)) {
      return;
    }
    if (parseInt(cardAmount) < parseInt(selectedCard.min_amount)) {
      return;
    }
    let cardInfo = `${selectedCard.name} ${selectedCountryCode}`
    navigate("/select-currency", { state: { currency: cardInfo, provider: selectedCard.product_id, amount: cardAmount, email: email, type: cardType } });
  };

  const closeModal = () => {
    setShowModal(false); 
    setCardAmount(0);
  }

  const handleCardSearchChange = (e) => {
    let cardSearch = e.target.value;
    setCardSearch(cardSearch)
    
    let filtered = [];

    if (cardSearch === "") {
      if (selectedCategory === "") {
        setFilteredCards(giftcards)
        filtered = giftcards
      } else {
        filtered = giftcards.filter(card => card.category === selectedCategory)
        setFilteredCards(filtered);
      }
    } else {
      filtered = giftcards.filter(card => card.name.toLowerCase().includes(cardSearch))
      setFilteredCards(filtered);
    }

    setOptions(filtered.length)
  };

  const handleCardAmountChange = (e) => {
    let cardAmount = e.target.value;
    setCardAmount(parseInt(cardAmount));
  };
  

  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }), 
    control: (base, state) => ({ 
      ...base, 
      textColor: '#000000',
      backgroundColor: '#0F0F0F', 
      borderColor: "#262626",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#7A7A7A" : "#262626"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#0F0F0F',
        color: '#000000',
        height: 35, 
        minHeight: 35,
        marginLeft: 5
      };
    },
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#0F0F0F"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555"
      },
      padding: 0,
      minHeight: "392px",
      boxShadow: "none",
      backgroundColor: '#0F0F0F'
    }),
    input: (base) => ({
      ...base,
      marginLeft: 5,
      '[type="text"]': {
        fontSize: 13,
        fontWeight: 900,
        color: 'white !important'
      }
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: 5
      ,
      fontSize: 16
    }),
    noOptionsMessage: base => ({ 
      ...base, 
      background: "#0F0F0F"
    })
  };

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center p-2 overflow-hidden">

    <>
      <div className={`${showModal ? 'block' : 'hidden'} z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto text-white`} id="ModalScrollable" tabindex="-1" aria-labelledby="ModalScrollableLabel" aria-hidden="true">
        <div className="sm:h-[calc(100%-3rem)] w-lg lg:w-880 my-6 mx-auto relative w-auto pointer-events-none">
          <div className="max-h-full overflow-hidden border-solid shadow-lg relative flex flex-col w-full pointer-events-auto bg-table-dark bg-clip-padding rounded-md outline-none text-current">
            <div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5 className="text-xl font-medium leading-normal text-white" id="exampleModalScrollableLabel">
                Gift Card Information
              </h5>
              <button type="button" className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="flex-auto overflow-y-auto relative p-4">
              <div className="flex items-center justify-center p-2">
                <img
                  src={selectedCard.card_image_url}
                  alt={selectedCard.name}
                  className="rounded-md h-64"
                />

              </div>
              <h1 className="pb-3">
                <strong>{selectedCard.name}</strong>
              </h1>
              <br/>
              <h3><strong>Description:</strong></h3>
              <div>
                <HTMLDisplay htmlString={selectedCard.description} />
              </div>
              <br/>
              <br/>
              <h3><strong>How to use:</strong></h3>
              <div>
                <HTMLDisplay htmlString={selectedCard.how_to_use} />
              </div>
              <br/>
              <br/>
              <h3><strong>Terms and Conditions:</strong></h3>
              <div>
                <HTMLDisplay htmlString={selectedCard.terms_and_conditions} />
              </div>
              <br/>
              <br/>
              <h3>Expiration Information:</h3>
              <p>{selectedCard.expiry_and_validity === "" ? "Does not expire." : selectedCard.expiry_and_validity}</p>
            </div>
            <div className="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <div className="gray-text flex justify-end items-center pt-2 w-96 lg:w-full">
                <div className="flex items-center justify-center">
                <span className="pe-3"><small className="gray-text">${selectedCard.min_amount} - ${selectedCard.max_amount} USD</small></span>
                  <div className="search-width w-80">
                    <input
                      type="number"
                      value={cardAmount}
                      placeholder={`$${selectedCard.min_amount}-$${selectedCard.max_amount}`}
                      onChange={handleCardAmountChange}
                      className="flex-1 py-2 px-2 address-frame rounded-l-md focus:outline-none text-white placeholder-gray-color"
                    />
                    <button
                      className="px-6 py-3 copy-btn text-white rounded-r-md"
                      disabled
                    >
                      <img src={dollarImg} alt="search logo" className="max-h-6"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 flex-wrap items-center justify-end pb-4 ps-4 pe-4 border-gray-200 rounded-b-md">
              <button type="button" className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out rounded-lg" data-bs-dismiss="modal" 
                onClick={closeModal}
              >
                Close
              </button>
              <button className="inline-block px-6 py-2.5 green-btn text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                onClick={purchaseCard}
                disabled={(parseInt(cardAmount) > parseInt(selectedCard.max_amount)) || (parseInt(cardAmount) < parseInt(selectedCard.min_amount))}
              >
                Select Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </>

      <div className="flex flex-col items-center h-full w-full">
        <div className="w-96 lg:w-full">
          <div className="flex justify-center pb-2">
            <div className="step-box border-fuchsia-800">
              <div className="step-text flex justify-center items-center">
                3
              </div>
            </div>
          </div>
          <div>
            <div className="text-white flex justify-center items-center pb-1">
              <strong>Step 3</strong>
            </div>
            <div className="gray-text flex justify-center items-center pb-4">
              Select a gift card
            </div>
            <hr className="step-divider"></hr>
            <div className="pb-2"></div>
          </div>
          <div className="gray-text flex justify-between items-center pb-4 w-96 lg:w-full">
            <span>
              Cards Available: {options}
            </span>
            <span>
              <Select className="basic-single w-80"
                placeholder="Card Category"
                isClearable={true} 
                isSearchable={true} 
                options={categories}
                menuPlacement={"auto"} 
                menuPosition="fixed"
                styles={selectStyles}
                formatOptionLabel={category => (
                  <div className="flex justify-left country-option">
                    <span className="ps-2 text-white country-options"><small>{category.label}</small></span>
                  </div>
                )}
                onChange={handleCategoryChange}
              />
            </span>
          </div>
          <div className="gray-text flex justify-center items-center pb-4 w-96 lg:w-full">
            <div className="flex items-center justify-center">
              <div className="search-width w-80">
                <input
                  type="text"
                  placeholder="Search Card Name"
                  onChange={handleCardSearchChange}
                  className="flex-1 py-2 px-2 address-frame rounded-l-md focus:outline-none text-white placeholder-gray-color"
                />
                <button
                  onClick={searchCards}
                  className="px-6 py-3 copy-btn text-white rounded-r-md hover:opacity-80"
                >
                  <img src={searchImg} alt="search logo" className="max-h-6"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto pb-20 w-full lg:w-full">
          <div className="flex flex-col">
            <div className="flex-1 flex flex-wrap items-start justify-center sm:justify-start gap-2 sm:gap-4 pt-2 w-96 lg:w-full">
              {filteredCards.map((card) => (
                <div
                  key={card.product_id} 
                  className="display-gift-card gift-card-select rounded-lg p-2 flex flex-col "
                  onClick={() => {selectCard(card)}}
                >
                  <div>
                    <div className="flex items-center justify-center">
                      <img
                        src={card.card_image_url}
                        alt={card.name}
                        className="h-32 max-w-80 rounded-md"
                      />
                    </div>
                    <div className="text-white pb-2 pt-1">
                      <div className="flex flex-col justify-start">
                        <span className="text-sm">{card.name}</span>
                        <small className="gray-text">${card.min_amount} - ${card.max_amount} USD</small>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-start mt-4 pt-10 pb-10">
              <button
                onClick={goToSelectCountry}
                className="hover:bg-opacity-80 text-white font-bold back-card"
              >
                ← <small>Back</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectGiftCard;
