import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select'
import check from "../src/assets/check.svg";

const ScrollableDialogBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cardType  = location.state.type;
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [options, setOptions] = useState(countries.length);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await fetch("https://paypawcards.com/paypawbackend/get-countries");
      const data = await response.json();
      const transformedData = data
        .map((country) => ({
          label: country.name,
          value: country.name,
          code: country.code.toLowerCase(),
          image: `https://flagcdn.com/${country.code.toLowerCase()}.svg#svgView(preserveAspectRatio(none))`
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
      setCountries(transformedData);
      
      setOptions(transformedData.length);
    };

    fetchCountries();

  }, []);

  const goToEmail = () => {
    navigate("/email-for-card", { state: { country: selectedCountryCode, type: cardType } });
  };

  const handleCountryChange = (country) => {
    // Toggle selection: if the same country is clicked again, deselect it; otherwise, select the new one.
    setSelectedCountryCode(country);
  };

  const handleInputChange = (input) => {
    let count = 0;

    countries.forEach(country => {
      if (country["value"].toLowerCase().includes(input.toLowerCase())) {
        count++;
      }
    });
    setOptions(count)
  };

  const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }), 
    control: (base, state) => ({ 
      ...base, 
      textColor: '#000000',
      backgroundColor: '#0F0F0F', 
      borderColor: "#262626",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#7A7A7A" : "#262626"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#0F0F0F',
        color: '#000000',
        height: 35, 
        minHeight: 35,
        marginLeft: 5
      };
    },
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#0F0F0F"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555"
      },
      padding: 0,
      minHeight: "392px",
      boxShadow: "none",
      backgroundColor: '#0F0F0F'
    }),
    input: (base) => ({
      ...base,
      marginLeft: 5,
      '[type="text"]': {
        fontSize: 13,
        fontWeight: 900,
        color: 'white !important'
      }
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: 5
      ,
      fontSize: 16
    }),
    noOptionsMessage: base => ({ 
      ...base, 
      background: "#0F0F0F"
    })
  };


  return (
    <div className="flex justify-center items-center">
      
      <div className="absolute flex justify-center items-center mx-8 sm:mx-0 rounded-lg">
        <div>
          <div className="flex flex-col items-center">
    
            <div className="flex justify-center pb-2">
              <div className="step-box border-fuchsia-800">
                <div className="step-text flex justify-center items-center">
                  1
                </div>
              </div>
            </div>
            <div>
              <div className="text-white flex justify-center items-center pb-1">
                <strong>Step 1</strong>
              </div>
              <div className="gray-text flex justify-center items-center pb-4">
                Select a region for your card
              </div>
              <hr className="step-divider"></hr>
              <div className="pb-2"></div>
            </div>

            <div className="flex justify-between w-96 md:w-607 pb-3">
              <span className="text-left text-white">Select Region</span>
              <span className="text-right gray-text">{options} available regions</span>
            </div>

            <div className="w-96 md:w-607 select-frame flex justify-center text-white">
              <div className="w-96 md:w-607 search-frame">
                <Select className="basic-single"
                  placeholder="Search region"
                  menuIsOpen={true}
                  isClearable={true} 
                  isSearchable={true} 
                  options={countries} 
                  menuPlacement={"auto"} 
                  menuPosition="fixed"
                  styles={selectStyles}
                  formatOptionLabel={country => (
                    <div className="flex justify-left country-option">
                      <img src={country.image} alt="country-image" className="rounded-md inline-flag"/>
                      <span className="ps-2 text-white country-options"><small>{country.label}</small></span>
                    </div>
                  )}
                  onChange={handleCountryChange}
                  onInputChange={handleInputChange}
                />
              </div>
            </div>
            
            <div className="pt-4">
              <button
                onClick={goToEmail}
                className={`w-96 md:w-607 confirm-btn ${cardType === 'gift' ? "green-btn" : "pink-btn"}`}
                disabled={selectedCountryCode === null}
              >
                <span className="text-white flex justify-center items-center"><img src={check} alt="check" height="12" width="12"/><span className="ps-3">Confirm</span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollableDialogBox;
