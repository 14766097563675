import React, { useState } from "react";
import HamburgerMenu from "../src/assets/hamburger.svg"; // Verify the path is correct
import { useWallet } from '@solana/wallet-adapter-react'; // Import the hook for wallet interaction
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'; // Import UI component for wallet connection
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';


// Ensure you have the styles for the wallet adapter UI components imported in your app,
// typically in your root component or main stylesheet:
// import '@solana/wallet-adapter-react-ui/styles.css';

const Header = ({ toggleNavbarVisibility }) => {
  const [headerTitle, setHeaderTitle] = useState("");
  const wallet = useWallet();

  return (
    <header className="text-white flex justify-between items-center p-2">
      <button className="block sm:hidden" onClick={toggleNavbarVisibility}>
        <img src={HamburgerMenu} alt="Hamburger Menu Icon" />
      </button>
      <h1 className="text-xl font-bold"></h1>
      <div>
        {/* WalletMultiButton automatically handles wallet connection/disconnection */}
        <WalletMultiButton />
      </div>
    </header>
  );
};

export default Header;
